<template>
  <div>

    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('suppliers_reports.country') }}</label>-->
<!--                <div class="input-group">-->
<!--                  <multiselect v-model="country"-->
<!--                               :placeholder="$t('suppliers_reports.country')"-->
<!--                               label="name" track-by="code2"-->
<!--                               :options="countries"-->
<!--                               :multiple="false"-->
<!--                               :taggable="false"-->
<!--                               :show-labels="false"-->
<!--                               :show-no-options="false"-->
<!--                               :show-no-results="false"-->
<!--                               @search-change="getCountries($event)">-->
<!--                  </multiselect>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('suppliers_reports.city') }}</label>-->
<!--                <div class="input-group">-->
<!--                  <multiselect v-model="city"-->
<!--                               :placeholder="$t('suppliers.city')"-->
<!--                               label="name" track-by="id"-->
<!--                               :options="cities"-->
<!--                               :multiple="false"-->
<!--                               :taggable="false"-->
<!--                               :show-labels="false"-->
<!--                               :show-no-options="false"-->
<!--                               :show-no-results="false">-->
<!--                  </multiselect>-->
<!--                </div>-->
<!--              </div>-->
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="supplier_id">{{ $t('supplier') }}</label>
                <multiselect id="supplier_id" v-model="supplier"
                             :placeholder="$t('purchases_payments_periodic_reports.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                  <label>{{$t('currency')}}</label>
                  <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.credit_min') }}</label>
                <input v-model="filters.credit_min" type="number" id="credit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.credit_max') }}</label>
                <input v-model="filters.credit_max" type="number" id="credit_max" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.debit_min') }}</label>
                <input v-model="filters.debit_min" type="number" id="debit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.debit_max') }}</label>
                <input v-model="filters.debit_max" type="number" id="debit_max" class="form-control">
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10 m-0">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">

        <!-- Filter -->
        <div>
          <div class="row justify-content-end">
            <div class="col-12 mt-5 mb-5 d-flex justify-content-end">
              <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                <download-excel
                    class="dropdown-item"
                    :fetch="dataReport"
                    :fields="json_fields"
                    :name="$t('suppliers_reports.suppliers_reports')+'.xls'">
                  <i class="la la-file-excel"></i>{{ $t('excel') }}
                </download-excel>
                <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                  <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                </button>
                <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                  <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                </button>
              </b-dropdown>
            </div>
          </div>
        </div>
        <!-- End Filter -->

        <v-server-table  :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table" id="clientReportsTable">
          <template slot="mobile" slot-scope="props">
            <span class="span-tel">{{ props.row.mobile }}</span>
          </template>
          <template slot="phone" slot-scope="props">
            <span class="span-tel">{{ props.row.phone }}</span>
          </template>
          <template slot="supplier_balance" slot-scope="props">
            <span class="d-block" v-for="(_balance, index) in props.row.supplier_balance" :key="'aa'+index">{{ _balance }}</span>
          </template>
        </v-server-table>

        <!--                <button class="btn btn-warning" @click="loadMore">-->
        <!--                    <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>-->
        <!--                </button>-->
      </div>
    </div>
    <div class="drowing-table">
      <table class="table table-bordered" id="summaryTable">
        <thead>
        <tr id="myRow">
          <th>{{ $t('suppliers_reports.supplier_code') }}</th>
          <th>{{ $t('suppliers_reports.full_name') }}</th>
          <th>{{ $t('suppliers_reports.address') }}</th>
          <th>{{ $t('suppliers_reports.mobile') }}</th>
          <th>{{ $t('suppliers_reports.phone') }}</th>
          <th>{{ $t('suppliers_reports.supplier_balance') }}</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(row2, index2) in dataList" :key="'c'+index2">
          <td>{{ row2.supplier_code }}</td>
          <td>{{ row2.full_name }}</td>
          <td>{{ row2.address }}</td>
          <td><span class="span-tel">{{ row2.mobile }}</span></td>
          <td><span class="span-tel">{{ row2.phone }}</span></td>
          <td>
            <span class="d-block" v-for="(_balance, index) in row2.supplier_balance" :key="'bb'+index">{{ _balance }}</span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
// import TableToExcel from "@linways/table-to-excel";


export default {
  name: "index-suppliers",
  components: {},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      dataList: [],
      filter: {
        sortBy: 'id',

        supplier_id: null,
        currency_id: null,
        credit_min: null,
        credit_max: null,
        debit_min: null,
        debit_max: null,
      },
      filters: {
        city_id: null,
        country_code: null,
      },
      columns: ['supplier_code', 'full_name', 'address', 'mobile', 'phone', 'supplier_balance'],
      data: [],
      countries: [],
      category: null,
      categories: [],
      cities: [],
      country: null,
      city: null,
      page: 0,
      currencies: [],
      supplier: [],
      suppliers: [],
      table_loading: false,
    }
  },
  watch: {
    // country: function (val) {
    //   if (val) {
    //     this.filters.country_code = val.code2;
    //     this.getCities(val.code2);
    //   } else {
    //     this.country = null;
    //   }
    // },
    // city: function (val) {
    //   if (val) {
    //     this.filters.city_id = val.id;
    //   } else {
    //     this.city = null;
    //   }
    // },
    // "filters.country_code": function (val) {
    //     if (val) {
    //         this.getCities(val);
    //     }
    // },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },

  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('suppliers_reports.supplier_code')] = 'supplier_code';
      fields[this.$t('suppliers_reports.full_name')] = 'full_name';
      fields[this.$t('suppliers_reports.address')] = 'address';
      fields[this.$t('suppliers_reports.mobile')] = 'mobile';
      fields[this.$t('suppliers_reports.phone')] = 'phone';
      fields[this.$t('suppliers_reports.supplier_balance')] = 'supplier_balance';



      return fields;
    },
    headings: function () {
      let that = this;
      let list = {
        supplier_code: that.$t('suppliers_reports.supplier_code'),
        full_name: that.$t('suppliers_reports.full_name'),
        address: that.$t('suppliers_reports.address'),
        mobile: that.$t('suppliers_reports.mobile'),
        phone: that.$t('suppliers_reports.phone'),
        supplier_balance: that.$t('suppliers_reports.supplier_balance'),
      };
      return list;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: this.headings,
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          // that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.suppliers_reports")}]);

  },
  created() {
    this.getCurrencies();
  },
  methods: {

    loadMore() {
      this.page = this.page + 1;

      this.dataReport();
    },

    dataReport() {
      this.setReportLog('excel', 'supplier report');
      return this.getDataList();
    },
    async getDataList() {
      return await ApiService.get(this.mainRoute, {params: {...this.filters, is_list: true}}).then(response => {
        this.dataList = response.data.data;
        return response.data.data;
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      // this.filters.country_code = null;
      this.filters.supplier_id = null;
      this.supplier = null;
      this.filters.currency_id = null;
      this.filters.credit_min = null;
      this.filters.credit_max = null;
      this.filters.debit_min = null;
      this.filters.debit_max = null;
      // this.country = null;
      // this.city = null;
      this.$refs.table.refresh();
    },

    // getCountries(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/countries`, {params: {filter: filter}}).then((response) => {
    //       this.countries = response.data.data;
    //     });
    //   }
    // },
    //
    // getCities(id) {
    //   ApiService.get(`base/dependency/cities/${id}`).then((response) => {
    //     this.cities = response.data.data;
    //   });
    // },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if(_currency){
          this.filters.currency_id = _currency.id;
        }
      });
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    },
    printData(tableId, type) {
      this.setReportLog('pdf', 'supplier report');

      let promise = this.getDataList();
      Promise.all([promise]).then(() => {
        exportPDFInnerAction(tableId, type, this.$t('suppliers_reports.suppliers_reports'), this.$t('suppliers_reports.suppliers_reports'));
      })

    },
    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.drowing-table, .selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>
  